import React from "react"
import { Link as GatsbyLink } from "gatsby"

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink

const CardContent = ({ title, bgColor }) => (
  <div
    className="grainOverlay CardContent"
    style={{
      backgroundColor: bgColor,
    }}
  >
    <div className="CardText">{title}</div>
  </div>
)

const Card = ({ children, to, activeClassName, title, bgColor, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        {...other}
        style={{ textDecoration: "none" }}
      >
        <CardContent title={title} bgColor={bgColor}>
          {children}
        </CardContent>
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...other} style={{ textDecoration: "none" }}>
      <CardContent title={title} bgColor={bgColor}>
        {children}
      </CardContent>
    </a>
  )
}

export default Card
