import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Card from "../components/card"
import SocialLinks from "../components/sociallinks"
import TopBanner from "../components/topBanner"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `anx`,
        `Annex Church`,
        `Albuquerque`,
        `Corrales NM`,
        `Albuquerque Church`,
        `Bible Church Albuquerque`,
        `Rio Rancho Church`,
        `Progressive Church Albuquerque`,
        `Bible Sermon`,
        `application`,
        `react`,
      ]}
    />

    <div className="fill-height-or-more">
      <TopBanner link="https://youtu.be/yRACcQBdVSA" />
      <div className="CardContainer">

        <Card
          title="Sermons"
          to="https://youtu.be/tLOu8BbQeXU"
          bgColor="#4fa9cb"
        />
        <Card className="box2" title="Hello" to="/hello" bgColor="#FF5B5E" />
        <Card className="box2" title="Prayer" to="/prayer" bgColor="#5EB1BF" />

        <Card
          title="Pastoral Care"
          to="https://anx.churchcenter.com/people/forms/117420"
          bgColor="#4F5150"
        />
        <Card
          title="Give"
          to="https://pushpay.com/pay/annexchurch/recurring"
          bgColor="#49DCB1"
        />
      </div>
    </div>
    <SocialLinks />
  </Layout>
)

export default IndexPage
