import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SocialLinks = () => (
  <StaticQuery
    query={graphql`
      query {
        image1: file(relativePath: { eq: "btn/ig.png" }) {
          childImageSharp {
            fluid(maxWidth: 150, maxHeight: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        image2: file(relativePath: { eq: "btn/fb.png" }) {
          childImageSharp {
            fluid(maxWidth: 150, maxHeight: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        image3: file(relativePath: { eq: "btn/yt.png" }) {
          childImageSharp {
            fluid(maxWidth: 150, maxHeight: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <footer className="grainOverlay">
        <div className="socialLinks">
          <a href="http://www.instagram.com/annexchurch">
            <Img fluid={data.image1.childImageSharp.fluid} />
          </a>
          <a href="http://www.facebook.com/annexchurch">
            <Img fluid={data.image2.childImageSharp.fluid} />
          </a>
          <a href="https://www.youtube.com/channel/UCbbLzHsTAeTFYmMJsE2vILw">
            <Img fluid={data.image3.childImageSharp.fluid} />
          </a>
        </div>
      </footer>
    )}
  />
)
export default SocialLinks
